<template>
  <v-dialog v-model="dialog" :width="email.type == null ? 750 : 600">
    <template v-slot:activator="{ on }">
      <base-border-card
        v-if="!hidden"
        :title="$t('emails.send')"
        @card_clicked="
          email.type = null;
          client_selected = null;
          sesion_selected = null;
          signal_selected = null;
          mail_target = null;
          fetchAppointmentTokenPayments();
          dialog = true;
        "
      >
      </base-border-card>
    </template>
    <v-card>
      <v-card-title>
        {{ $t("emails.send") }}
      </v-card-title>
      <v-card-subtitle v-if="sub_title" class="mt-0 text-center primary--text">
        {{ sub_title }}
      </v-card-subtitle>

      <div class="close">
        <v-btn
          icon
          @click="
            (dialog = false),
              (email.type = null),
              (client_selected = null),
              (mail_target = null)
          "
          small
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-row v-if="email.type == null && special == false" justify="center">
          <v-col cols="8" md="4" v-for="(mt, i) in mail_types" :key="i">
            <base-border-card
              :title="$tc(mail_types[i], 1)"
              @card_clicked="email.type = mail_types[i]"
              :disabled="
                (mail_types[i] == 'F.CONSENTIMIENTO' &&
                  appointment.available_dates.length == 0) ||
                  (mail_types[i] == 'PAGA Y SEÑAL' && tokenPayments.length == 0)
              "
            >
            </base-border-card>
          </v-col>
        </v-row>

        <v-row
          v-else-if="email.type != null && mail_target == null"
          justify="center"
        >
          <div class="back" v-if="email.type != null">
            <v-btn icon @click="email.type = null" small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <v-col cols="8" md="6">
            <base-border-card
              :title="'EMAIL'"
              @card_clicked="mail_target = 'EMAIL'"
            >
            </base-border-card>
          </v-col>
          <v-col cols="8" md="6">
            <base-border-card
              :title="'WHATSAPP'"
              @card_clicked="mail_target = 'WHATSAPP'"
            >
            </base-border-card>
          </v-col>
        </v-row>

        <v-row
          v-else-if="
            email.type == 'F.CONSENTIMIENTO' && sesion_selected == null
          "
          justify="center"
        >
          <div class="back">
            <v-btn icon @click="email.type = null" small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <v-col
            cols="8"
            md="4"
            v-for="(ad, i) in appointment.available_dates"
            :key="i"
          >
            <base-border-card
              :title="ad.date"
              @card_clicked="sesion_selected = ad"
            >
            </base-border-card>
          </v-col>
        </v-row>
        <v-row
          v-else-if="email.type == 'PAGA Y SEÑAL' && signal_selected == null"
          justify="center"
        >
          <div class="back">
            <v-btn icon @click="email.type = null" small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <v-col cols="8" md="4" v-for="(tp, i) in tokenPayments" :key="i">
            <base-border-card
              :title="tp.price + '€'"
              @card_clicked="signal_selected = tp"
              :two_lines="$vuetify.breakpoint.mdAndUp"
            >
            </base-border-card>
          </v-col>
        </v-row>

        <v-row
          id="customer_select"
          v-else-if="
            client_selected == null && email.type !== 'NEW_ONLINE_DATE'
          "
          justify="center"
        >
          <div
            class="back"
            v-if="email.type == 'DISEÑO' && mail_target == null"
          >
            <v-btn icon @click="email.type = null" small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <div
            class="back"
            v-else-if="email.type == 'F.CONSENTIMIENTO' && mail_target == null"
          >
            <v-btn icon @click="sesion_selected = null" small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <div
            class="back"
            v-else-if="email.type == 'PAGA Y SEÑAL' && mail_target == null"
          >
            <v-btn icon @click="signal_selected = null" small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <div
            class="back"
            v-else-if="email.type != null && mail_target != null"
          >
            <v-btn icon @click="mail_target = null" small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <v-col cols="8" md="6">
            <base-border-card
              :title="'OTRO'"
              @card_clicked="client_selected = 'NUEVO'"
              :disabled="email.type == 'F.CONSENTIMIENTO'"
            >
            </base-border-card>
          </v-col>
          <v-col cols="8" md="6" v-if="special == false">
            <base-border-card
              :title="'CLIENTE '"
              @card_clicked="client_selected = 'EXISTENTE'"
              :disabled="appointment.extra_customers.length == 0"
            >
            </base-border-card>
          </v-col>
        </v-row>

        <v-row
          v-else-if="client_selected == 'EXISTENTE' && special == false"
          justify="center"
          align="center"
        >
          <div class="back">
            <v-btn icon @click="client_selected = null" small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <v-col
            cols="8"
            md="6"
            v-for="(ec, i) in appointment.extra_customers"
            :key="i"
          >
            <base-border-card
              :title="ec.user.fullname"
              :subtitle="
                (ec.user.email == null && mail_target == 'EMAIL'
                  ? $t('no_mail')
                  : '') ||
                  (ec.user.phone == null && mail_target == 'WHATSAPP'
                    ? $t('no_whatsapp')
                    : '')
              "
              @card_clicked="enviarMail(ec)"
              :disabled="
                appointment.extra_customers.length == 0 ||
                  (ec.user.email == null && mail_target == 'EMAIL') ||
                  (ec.user.phone == null && mail_target == 'WHATSAPP')
              "
            >
            </base-border-card>
          </v-col>
        </v-row>
        <v-row v-else justify="center">
          <div class="back">
            <v-btn
              v-if="(email.type = 'NEW_ONLINE_DATE')"
              icon
              @click="(client_selected = null), (mail_target = null)"
              small
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn v-else icon @click="client_selected = null" small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <v-col v-if="mail_target == 'EMAIL'" cols="10">
            <v-text-field
              v-model="mailNewClient"
              :label="$t('type_mail')"
              class="semidense"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col v-else cols="10">
            <v-text-field
              v-model="phoneNewClient"
              :label="$t('type_phone')"
              class="semidense"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="client_selected == 'NUEVO' && email.type != null"
            elevation="0"
            @click="sendMailNewCostumer(ec)"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            class="ml-2"
          >
            <v-icon style="margin-right: 5px" size="14px">$send</v-icon>
            {{ $t("send", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SendMail",
  props: {
    appointment: { type: Object, required: false },
    url: { type: String, required: false },
    appointmentDates: { type: Object, required: false },
    hidden: { type: Boolean, default: true },
    special: { type: Boolean, required: false, default: false },
  },

  data() {
    return {
      mail_types: ["DISEÑO", "F.CONSENTIMIENTO", "PAGA Y SEÑAL"],
      dialog: false,
      searchCustomer: "",
      isLoadingCustomer: false,
      client_selected: null,
      sesion_selected: null,
      signal_selected: null,
      tokenPayments: [],
      mail_target: null,
      email: {
        type: null,
        mail: null,
        link: null,
      },
      mailNewClient: null,
      phoneNewClient: null,
    };
  },
  watch: {},
  computed: {
    sub_title() {
      if (this.email.type == null) {
        return this.$t("send_actions.action_send");
      } else if (this.email.type != null && this.mail_target == null) {
        return this.$t("send_actions.target");
      }

      switch (this.mail_target) {
        case "EMAIL":
          if (
            this.email.type == "F.CONSENTIMIENTO" &&
            this.sesion_selected == null
          ) {
            return this.$t("send_actions.choose_sesion");
          } else if (
            this.email.type == "PAGA Y SEÑAL" &&
            this.signal_selected == null
          ) {
            return this.$t("send_actions.choose_payment_token");
          }
          if (this.email.type != null && this.client_selected == null) {
            return this.$t("send_actions.choose_customer_mail");
          }
          if (
            this.email.type != null &&
            (this.client_selected == "NUEVO" ||
              this.client_selected == "EXISTENTE")
          ) {
            return this.$t("send_actions.choose");
          }
        case "WHATSAPP":
          if (
            this.email.type == "F.CONSENTIMIENTO" &&
            this.sesion_selected == null
          ) {
            return this.$t("send_actions.choose_sesion");
          } else if (
            this.email.type == "PAGA Y SEÑAL" &&
            this.signal_selected == null
          ) {
            return this.$t("send_actions.choose_payment_token");
          }
          if (this.email.type != null && this.client_selected == null) {
            return this.$t("send_actions.choose_customer_whatsapp");
          }
          if (
            this.email.type != null &&
            (this.client_selected == "NUEVO" ||
              this.client_selected == "EXISTENTE")
          ) {
            return this.$t("send_actions.choose");
          }
      }
    },
  },
  methods: {
    getWhatsURL(ec) {
      console.log("WHATSAP URL", ec);
      let url = "";
      let phone = ec.user.phone;
      console.log("PHONE", phone);
      if (phone[0] !== "+") phone = "+34" + phone;
      let msg = "";
      switch (this.email.type) {
        case "DISEÑO":
          msg = this.$t("msg_send.design");
          break;
        case "F.CONSENTIMIENTO":
          msg = this.$t("msg_send.consent");
          break;
        case "PAGA Y SEÑAL":
          msg = this.$t("msg_send.payment_token");
          break;
        case "DEPÓSITO":
          msg = this.$t("msg_send.deposit");
          break;
        case "ONLINE":
          msg = this.$t("msg_send.online");
          break;
        case "CITA":
          msg = this.$t("msg_send.appointment");
          break;
      }
      msg += this.URL(ec);
      url = `https://wa.me/${phone}?text=${encodeURIComponent(msg)}`;
      return url;
    },

    open(mail_type) {
      this.email.type = mail_type;
      this.dialog = true;
    },
    ...mapActions("appointments", ["sendMail", "getAppoinmentsTokenPayments"]),

    enviarMail(ec) {
      console.log("EC", ec);
      //this.email.appDate = this.appointment.available_dates[0].date;
      if (this.mailNewClient != null) {
        this.email.mail = ec.user.email;
      } else {
        this.email.mail = ec.user.phone;
      }
      this.email.link = this.URL(ec);
      this.email.appointment_id = this.appointment.id;
      this.email.mail_target = this.mail_target;
      console.log("SEND", this.email);
      //SI HA SELECCIONAT MAIL
      if (this.mail_target == "EMAIL") {
        this.sendMail({
          appointment_id: this.appointment.id,
          mail: this.email,
        }).then((res) => {
          console.log("SENDING MAIL", res);
          this.$alert(this.$t("mail_send"), "", "");
          this.dialog = false;
        });
        //SINO CALCULEM URL WHATS I WINDOWS.LOCATION
      } else {
        console.log(ec);
        let url = this.getWhatsURL(ec);
        //GUARDAR AL BACK
        this.sendMail({
          appointment_id: this.appointment.id,
          mail: this.email,
        }).then((res) => {
          console.log("SENDING WHATSAPP", res);
          this.$alert(this.$t("whatsapp_send"), "", "");
          this.dialog = false;
        });
        window.open(url, "_blank");
        console.log("URL ", url);
      }
      this.email.type = null;
      this.client_selected = null;
      this.sesion_selected = null;
      this.signal_selected = null;
      this.mail_target = null;
      this.dialog = false;
    },

    sendMailNewCostumer() {
      console.log("SENDNEW", this.email);
      //this.email.appDate = this.appointment.available_dates.date;
      if (this.mailNewClient != null) {
        this.email.mail = this.mailNewClient;
      } else {
        this.email.mail = this.phoneNewClient;
      }
      this.email.link = this.URL({ user: { email: this.mailNewClient } });
      this.email.appointment_id = this.appointment.id;
      this.email.mail_target = this.mail_target;
      if (this.mail_target == "EMAIL") {
        this.sendMail({
          appointment_id: this.appointment.id,
          mail: this.email,
        }).then((res) => {
          console.log("SENDING MAIL NEW", res);
          this.$alert(this.$t("mail_send"), "", "");
          this.dialog = false;
        });
      } else {
        let url = this.getWhatsURL({ user: { phone: this.phoneNewClient } });
        //GUARDAR AL BACK
        this.sendMail({
          appointment_id: this.appointment.id,
          mail: this.email,
        }).then((res) => {
          console.log("SENDING WHATSAPP NEW", res);
          this.$alert(this.$t("whatsapp_send"), "", "");
          this.dialog = false;
        });
        window.open(url, "_blank");
        console.log("URL ", url);
      }
      this.email.type = null;
      this.client_selected = null;
      this.sesion_selected = null;
      this.signal_selected = null;
      this.mail_target = null;
      this.dialog = false;
    },
    URL(ec) {
      let url = "";
      switch (this.email.type) {
        case "DISEÑO":
          url = `${window.location.origin}/?#/cliente/${this.appointment.customer.id}/appointment/${this.appointment.id}`;
          //console.log("URL", url);
          return url;
        case "F.CONSENTIMIENTO":
          url = `${window.location.origin}/?#/cliente/?#/inscription/appointment/${this.appointment.id}/consent/${ec.id}/${this.sesion_selected.id}`;
          return url;
        case "PAGA Y SEÑAL":
          if (this.signal_selected.id) {
            url = `${window.location.origin}/?#/inscription/studio/quickink_studio/appointment/${this.appointment.id}/appointment_token/${this.signal_selected.id}`;
            return url;
          } else if (this.signal_selected) {
            url = `${window.location.origin}/?#/inscription/studio/quickink_studio/appointment/${this.appointment.id}/appointment_token/${this.signal_selected}`;
            return url;
          }
        case "DEPÓSITO":
          url = `${window.location.origin}/?#/cliente/${this.appointment.customer.id}/appointment/${this.appointment.id}`;
          return url;
        case "ONLINE":
          url = `${window.location.origin}/?#/inscription/studio/${this.appointment.studio.token}/appointment/${this.appointment.id}/sesion/${this.appointmentDates.id}`;
          return url;
        case "CITA":
          url = `${window.location.origin}/?#/cliente/${this.appointment.customer_id}/appointment/${this.appointment.id}`;
          return url;
        case "CLIENTE":
          url = `${window.location.origin}/?#/inscription/appointment/${this.appointment.id}/consent/${this.signal_selected}`;
          return url;
        case "NEW_ONLINE_DATE":
          url = `${window.location.origin}/?#/inscription/studio/studios_mt/appointment/pre`;
          return url;
        default:
          break;
      }
    },
    //FETCH appointmentstokenpayments
    fetchAppointmentTokenPayments() {
      console.log("fetchAppointmentTokenPayments");
      console.log(this.appointment);
      console.log(this.appointment.id);
      this.getAppoinmentsTokenPayments(this.appointment.id).then((data) => {
        this.tokenPayments = data;
        console.log(this.tokenPayments);
      });
    },
  },
};
</script>
<style lang="sass"></style>
